<template>
	<common-table
			:itemScope="['std', 'output', 'status']"
			:headers="headers"
			:listData="listData"
			@changePage="changePage"
			:loading="loading"
			:btnAction="true"
			@search="search"
			@save="save"
			@edit="edit"
			ref="ct"
			:leftConfig="leftConfig"
			:rightConfig="rightConfig"
			:listDeploy="listDeploy"
			pageTitle="多语言翻译"
			:btnActionEvent="btnActionEvent"
			@otherRightOperate="otherRightOperate"
	>
		<template v-slot:std="{ item }">
			<div class="d-content one-line" v-html="item.std"/>
		</template>
		<template v-slot:output="{ item }">
			<div class="d-content one-line" v-html="item.output"/>
		</template>
		<template v-slot:status="{ item }">
			<div v-if="item.status === '未翻译'" style="color: #ff4949">{{item.status}}</div>
			<div v-else>{{item.status}}</div>
		</template>
	</common-table>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/systemManage/lang/index.js';
import editorText from '@/components/editorText/editorText.vue';

export default {
	mixins: [commonCurd],
	data() {
		return {
			api,
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
			],
			leftConfig: [
				{type: 'input', label: '输入翻译原文关键字', property: 'searchkey', items: []},
				{
					type: 'select',
					label: '选择状态',
					property: 'status',
					items: [{code: '0', desc: '未翻译'}, {code: 1, desc: '已翻译'}]
				}
			],
			rightConfig: [
				{label: '同步', icon: 'shuaxin', event: 'sync'}
			],
			headers: [
				{text: '翻译原文', value: 'std'},
				{text: '原文类型', value: 'textypedec'},
				{text: '状态', value: 'status'},
				{text: '翻译文本', value: 'output'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '翻译原文'
							}
						}
					},
					{
						cols: 12,
						property: 'std',
						connector: {
							props: {
								required: true,
								label: "",
								solo: true,
								rows: 2,
								outlined: true,
								flat: true
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '翻译文本'
							}
						}
					},
					{
						cols: 12,
						property: 'output',
						connector: {
							props: {
								required: true,
								label: "",
								solo: true,
								rows: 2,
								outlined: true,
								flat: true
							}
						}
					}
				]
			}
		}
	},
	methods: {
		otherRightOperate(event) {
			if (event === this.rightConfig[0].event) {
				this.confirm.showConfirm("确定同步当前文本数据吗").then(() => {
					this.axios.post(this.api.sync, {lang: 'en'}).then(() => {
						this.list()
					})
				}).catch(() => {
				});
			}
		},
		beforeEdit(form) {
			this.listDeploy.row.map(item => {
				if (item.subassembly !== 'div') {
					item.subassembly = form.textype === 0 ? 'VTextarea' : editorText
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.d-content {
	width: 500px;
}

::v-deep {
	.v-data-table__wrapper table > tbody tr > td {
		line-height: normal !important;
	}
}
</style>
